.markdown-preview {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    word-wrap: break-word;
    color: #333;
  }
  
  .markdown-preview h1,
  .markdown-preview h2,
  .markdown-preview h3,
  .markdown-preview h4,
  .markdown-preview h5,
  .markdown-preview h6 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold;
  }
  
  .markdown-preview h1 {
    font-size: 2em;
  }
  
  .markdown-preview h2 {
    font-size: 1.75em;
  }
  
  .markdown-preview h3 {
    font-size: 1.5em;
  }
  
  .markdown-preview h4 {
    font-size: 1.25em;
  }
  
  .markdown-preview h5 {
    font-size: 1em;
  }
  
  .markdown-preview h6 {
    font-size: 0.875em;
  }
  
  .markdown-preview p {
    margin: 1em 0;
  }
  
  .markdown-preview a {
    color: #0366d6;
    text-decoration: none;
  }
  
  .markdown-preview a:hover {
    text-decoration: underline;
  }
  
  .markdown-preview ul,
  .markdown-preview ol {
    padding-left: 20px;
    margin: 1em 0;
  }
  
  .markdown-preview li {
    margin-bottom: 0.5em;
  }
  
  .markdown-preview code {
    font-family: 'Courier New', monospace;
    padding: 2px 4px;
    background-color: #f5f5f5;
    border-radius: 4px;
  }
  
  .markdown-preview pre {
    font-family: 'Courier New', monospace;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 4px;
    overflow-x: auto;
  }
  
  .markdown-preview pre code {
    background-color: transparent;
    padding: 0;
  }
  
  .markdown-preview blockquote {
    margin: 1em 0;
    padding-left: 1em;
    border-left: 4px solid #ddd;
  }
  
  .markdown-preview img {
    max-width: 100%;
  }
  
  .markdown-preview strong {
    font-weight: bold;
  }
  
  .markdown-preview em {
    font-style: italic;
  }
  